import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { Heading } from "../components/typography"
import BookSidebar from "../components/bookSidebar"
import Resources from "../components/resources"
import Gut from "../components/gut"
import { Helmet } from "react-helmet"

const BesResourcesPage = ({ data: { book }, pageContext }) => {
  // console.log(book, pageContext)
  return (
    <Layout>
      <SEO title={book.title} />
      <Helmet>
        <meta property="og:image" content={book.cover.url} />
      </Helmet>
      <Wrapper>
        <Container>
          <CustomRow>
            <Col lg={5}>
              <BookSidebar book={book} />
            </Col>
            <Col lg={7}>
              <Gut gutter={2}>
                <Heading>Didattica per tutti</Heading>
                <Resources resources={book.besResources} />
                <Link to="../">‹ Torna al libro</Link>
              </Gut>
            </Col>
          </CustomRow>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomRow = styled(Row)`
  @media screen and (max-width: 991px) {
    & > * {
      margin-bottom: 4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export default BesResourcesPage

export const query = graphql`
  query BookBesResourcesQuery($originalId: String!) {
    book: datoCmsBook(originalId: { eq: $originalId }) {
      title
      slug
      originalId
      cover {
        fluid(maxWidth: 400) {
          ...GatsbyDatoCmsFluid
        }
      }
      description
      pageNumber
      isbn
      price
      digitalDownload
      authors {
        name
        id
      }
      bookSheet {
        url
        format
      }
      adoptionRelation {
        url
        format
      }
      video {
        providerUid
      }
      additionalResources {
        url
        title
        format
        originalId
      }
      teachersResources {
        url
        title
        format
        originalId
      }
      besResources {
        url
        title
        format
        originalId
      }
    }
  }
`
